import React, { useState } from 'react'

import { Location } from '@reach/router'
import { navigate } from 'gatsby'
import { Fade, Slide } from 'react-reveal'
import styled from 'styled-components'

import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  useMediaQuery,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import HeaderLogoImage from './images/HeaderLogoImage'
import MobileNavigationMenu from './MobileNavigationMenu'

import materialUiTheme from '../material-ui-theme'
import media from '../utils/media'

const Header = () => {
  const isLargeTablet = useMediaQuery(`(min-width:600px) and (max-width:960px)`)
  const menuButtonSize = isLargeTablet ? 'medium' : 'large'

  const [isMobileNavigationMenuOpen, setIsMobileNavigationMenuOpen] = useState(
    false
  )

  const toggleMobileNavigationMenu = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setIsMobileNavigationMenuOpen(!open)
  }

  return (
    <Location>
      {({ location }) => {
        const routesWithActiveProperty = routes
          .map(route => {
            const { path } = route

            return {
              ...route,
              isActive: path === location.pathname,
            }
          })
          .sort((a, b) => a.navOrder - b.navOrder)

        return (
          <>
            <S.AppBar position="fixed">
              <S.Box />
              <Container>
                <S.Toolbar>
                  <Slide top>
                    <HeaderLogoImage onClick={() => navigate('/')} />
                  </Slide>
                  <Hidden smUp>
                    <S.IconButton
                      onClick={toggleMobileNavigationMenu(
                        isMobileNavigationMenuOpen
                      )}
                    >
                      <MenuIcon style={{ fontSize: '3rem' }} />
                    </S.IconButton>
                  </Hidden>
                  <Hidden xsDown>
                    <Fade top>
                      <Grid container spacing={1} style={{ width: 'unset' }}>
                        {routesWithActiveProperty.map(
                          ({ id, label, path, isActive }) => (
                            <S.Grid item isActive={isActive} key={id}>
                              <S.MenuButton
                                component="a"
                                size={menuButtonSize}
                                onClick={() => navigate(path)}
                              >
                                {label}
                              </S.MenuButton>
                            </S.Grid>
                          )
                        )}
                      </Grid>
                    </Fade>
                  </Hidden>
                </S.Toolbar>
              </Container>
            </S.AppBar>
            <MobileNavigationMenu
              open={isMobileNavigationMenuOpen}
              routesData={routesWithActiveProperty}
              onClose={toggleMobileNavigationMenu(isMobileNavigationMenuOpen)}
              onOpen={toggleMobileNavigationMenu(isMobileNavigationMenuOpen)}
              onNavigationMenuItemClick={route => navigate(route)}
            />
          </>
        )
      }}
    </Location>
  )
}

// TODO: Relocate this variable to proper module
export const routes = [
  {
    id: 'menu',
    label: 'MENU',
    path: '/menu',
    navOrder: 2,
  },
  {
    id: 'about_us',
    label: 'ABOUT US',
    path: '/about-us',
    navOrder: 1,
  },
  {
    id: 'store_locator',
    label: 'STORE LOCATOR',
    path: '/store-locator',
    navOrder: 4,
  },
  {
    id: 'promo',
    label: 'PROMO',
    path: '/promo',
    navOrder: 3,
  },
  {
    id: 'contact_us',
    label: 'CONTACT US',
    path: '/contact-us',
    navOrder: 5,
  },
]

const S = {
  AppBar: styled(AppBar)`
    background-color: ${materialUiTheme.palette.common.white};
    box-shadow: none;
    padding: unset;
  `,
  Box: styled(Box)`
    background: ${materialUiTheme.palette.secondary.main};
    height: 20px;
  `,
  Grid: styled(({ isActive, ...rest }) => <Grid {...rest} />)`
    ${({ isActive }) => `
    ${isActive &&
      `
        border-bottom: 1px solid ${materialUiTheme.palette.secondary.main};
      `}
    `}
  `,
  IconButton: styled(IconButton)`
    color: ${materialUiTheme.palette.primary.main};
    margin-left: auto;
  `,
  MenuButton: styled(Button)`
    color: ${materialUiTheme.palette.secondary.dark};
  `,
  Toolbar: styled(Toolbar)`
    max-height: 88px;
    justify-content: center;

    ${media.sm`
      justify-content: space-between;
    `}
  `,
}

export default Header
