import React from 'react'
import PropTypes from 'prop-types'

import { graphql, navigate, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import {
  Box,
  Container,
  Grid,
  Typography,
  Hidden,
  Button,
  IconButton,
} from '@material-ui/core'
import {
  EmailOutlined as EmailOutlinedIcon,
  PlaceOutlined as PlaceOutlinedIcon,
} from '@material-ui/icons'
import { Parallax } from 'react-parallax'
import { Fade, Zoom } from 'react-reveal'

import muiTheme, { fonts } from '../material-ui-theme'
import media from '../utils/media'

import ContainedSection from './ContainedSection'
import Fab from './Fab'
import { routes } from './Header'
import FacebookLogo from './icons/FacebookLogo'
import FooterLogoImage from './images/FooterLogoImage'

const Footer = props => {
  const { showGetInTouchBanner } = props

  const data = useStaticQuery(graphql`
    query {
      footerBannerBg: file(relativePath: { eq: "footer-banner.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.FooterWrapper>
      <S.FooterMainSectionWrapper>
        {showGetInTouchBanner && (
          <Parallax bgImage={data.footerBannerBg.childImageSharp.fluid.src}>
            <S.BannerOverlayBox>
              <ContainedSection>
                <Grid container>
                  <Hidden xsDown>
                    <Grid item sm={2}></Grid>
                  </Hidden>
                  <Grid item xs={12} sm={7} md={5}>
                    <Zoom>
                      <Typography
                        style={{
                          marginBottom: muiTheme.spacing(3),
                        }}
                        variant="h5"
                      >
                        Get In Touch with us
                      </Typography>
                    </Zoom>
                    <Fade>
                      <Box alignItems="center" display="flex" mb={3}>
                        <Fade left>
                          <PlaceOutlinedIcon
                            style={{ marginRight: muiTheme.spacing(3) }}
                          />
                          <Typography variant="h6">
                            Unit LG-6, Cityland 10 Tower I 156 H.V. Dela Costa
                            St., Ayala North, Makati City
                          </Typography>
                        </Fade>
                      </Box>
                    </Fade>

                    <Box alignItems="center" display="flex" mb={7}>
                      <Fade left>
                        <EmailOutlinedIcon
                          style={{ marginRight: muiTheme.spacing(3) }}
                        />
                        <Typography
                          style={{ wordBreak: 'break-word' }}
                          variant="h6"
                        >
                          customer_care@northpark.com.ph
                        </Typography>
                      </Fade>
                    </Box>
                    <Fade top>
                      <Fab
                        color="secondary"
                        variant="extended"
                        onClick={() => navigate('/contact-us')}
                      >
                        Send an Inquiry
                      </Fab>
                    </Fade>
                  </Grid>
                </Grid>
              </ContainedSection>
            </S.BannerOverlayBox>
          </Parallax>
        )}
        <Container
          style={{ padding: muiTheme.spacing(3), position: 'relative' }}
        >
          <Grid container alignItems="center">
            <Grid item xs={12} sm={2} md={2} lg={3}>
              <FooterLogoImage />
            </Grid>
            <Grid item xs={12} sm={10} md={8} lg={8}>
              <Fade top>
                <S.LinksContainerGrid
                  container
                  wrap="wrap"
                  spacing={1}
                  style={{ width: 'unset' }}
                >
                  {[...routes]
                    .sort((a, b) => a.navOrder - b.navOrder)
                    .map(({ id, label, path }) => (
                      <S.MenuButtonWrapper item key={id}>
                        <S.MenuButton
                          component="a"
                          size="small"
                          onClick={() => navigate(path)}
                        >
                          {label}
                        </S.MenuButton>
                      </S.MenuButtonWrapper>
                    ))}
                  <Hidden xsDown mdUp>
                    <Grid item xs={12}>
                      <Fade top>
                        <SocialMediaIcons />
                      </Fade>
                    </Grid>
                  </Hidden>
                </S.LinksContainerGrid>
              </Fade>
            </Grid>
            <Hidden only="sm">
              <Grid item xs={12} md={2} lg={1}>
                <Fade top>
                  <SocialMediaIcons />
                </Fade>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </S.FooterMainSectionWrapper>
      <S.FooterSecondarySectionWrapper>
        <Container
          style={{
            paddingBottom: muiTheme.spacing(1),
            paddingTop: muiTheme.spacing(1),
          }}
        >
          <S.FooterSecondarySectionContentWrapper>
            <Fade top>
              <S.CopyrightText>
                COPYRIGHT {new Date().getFullYear()} ALL RIGHTS RESERVED
              </S.CopyrightText>
            </Fade>
          </S.FooterSecondarySectionContentWrapper>
        </Container>
      </S.FooterSecondarySectionWrapper>
    </S.FooterWrapper>
  )
}

const S = {
  BannerOverlayBox: styled(Box)`
    background-color: rgba(0, 0, 0, 0.54);
    color: ${muiTheme.palette.common.white};
    min-height: 632px;

    ${media.sm`
      min-height: 664px;
    `}

    ${media.md`
      min-height: 672px;
    `}
  `,
  CopyrightText: styled(Typography).attrs({
    component: 'span',
    variant: 'body2',
  })``,
  FooterWrapper: styled(Box).attrs({ component: 'footer' })`
    margin-top: 60px;
  `,
  FooterMainSectionWrapper: styled(Box)`
    background-color: ${muiTheme.palette.secondary.main};
    position: relative;
  `,
  FooterSecondarySectionWrapper: styled(Box)`
    background-color: #474647;
  `,
  FooterSecondarySectionContentWrapper: styled(Box).attrs({ color: 'white' })`
    text-align: center;
  `,
  LinksContainerGrid: styled(Grid)`
    margin-top: ${muiTheme.spacing(3)}px;
    justify-content: center;

    ${media.md`
      justify-content: unset;
      margin-top: unset;
      margin-left: 10%;
    `}
  `,
  MenuButton: styled(Button)`
    color: ${muiTheme.palette.common.white};
    font-family: ${fonts['Belmar-Normal']};
  `,
  MenuButtonWrapper: styled(Grid)`
    ${media.sm`
      margin: auto;
    `}

    ${media.lg`
      margin: unset;
    `}
  `,
  SecondaryLinksWrapper: styled(Box)`
    margin-top: ${muiTheme.spacing(1)}px;

    > :first-child {
      margin-right: ${muiTheme.spacing(2)}px;
    }

    ${media.sm`
      margin-top: 0;
    `}
  `,
  SocialMediaLi: styled(props => <Grid {...props} item component="li" />)`
    &:hover {
      opacity: 0.8;
    }
  `,
  SocialMediaUl: styled(props => (
    <Grid
      {...props}
      container
      component="ul"
      style={{
        listStyle: 'none',
        padding: 0,
      }}
    />
  ))`
    list-style: none;
    justify-content: center;
    padding: 0;

    ${media.sm`
      justify-content: flex-end;
    `}

    & > li:not(:first-child) {
      margin-left: ${muiTheme.spacing(3)}px;
    }
  `,
  SocialIconButton: styled(IconButton)`
    color: ${muiTheme.palette.common.white};
  `,
  SocialIconsBox: styled(Box)`
    margin-top: ${muiTheme.spacing(1)}px;
    text-align: center;

    ${media.md`
      margin-top: unset;
    `}
  `,
}

const SocialMediaIcons = () => {
  return (
    <S.SocialIconsBox>
      <S.SocialIconButton
        component="a"
        href="https://www.facebook.com/machickenmamihouse/"
        target="_blank"
      >
        <FacebookLogo />
      </S.SocialIconButton>
    </S.SocialIconsBox>
  )
}

Footer.propTypes = {
  showGetInTouchBanner: PropTypes.bool,
}

export default Footer
