/**
 * @deprecated
 * Use material-ui theming solution
 */

const baseColors = {
  $white: '#FFFFFF',
}

const appBaseColors = {}

const componentColors = {
  $headerMenuButtonColor: baseColors.$white,
  $headerMenuGridBorderBottomColor: baseColors.$white,
  $headerHamburgerIconButtonColor: baseColors.$white,
}

const componentStateColors = {}

const theme = {
  ...baseColors,
  ...componentColors,
  ...appBaseColors,
  ...componentStateColors,
}

export default theme
