import styled from 'styled-components'

import { Container } from '@material-ui/core'

import media from '../utils/media'

const ContainedSection = styled(Container)`
  padding-bottom: 20px;
  padding-top: 40px;

  ${media.sm`
    padding-top: 60px;
  `}

  ${media.md`
    padding-top: 100px;
  `}
`

export default ContainedSection
