/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialUiThemeProvider } from '@material-ui/styles'

import { Container } from '@material-ui/core'

import styledComponentTheme from '../theme'
import materialUiTheme from '../material-ui-theme'

import Footer from './Footer'
import Header from './Header'
import PageHeader from './PageHeader'

import './styles.css'

const Layout = ({ children, pageHeaderBackgroundImage, pageTitle }) => {
  useEffect(() => {
    document.getElementById('loader').style.display = 'none'
    document.getElementsByTagName('body')[0].style.overflow = 'unset'
  })

  return (
    <StyledComponentThemeProvider theme={styledComponentTheme}>
      <MaterialUiThemeProvider theme={materialUiTheme}>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                }
              }
            }
          `}
          render={data => (
            <>
              <Header />
              {pageHeaderBackgroundImage && pageTitle && (
                <PageHeader
                  backgroundImage={pageHeaderBackgroundImage}
                  pageTitle={pageTitle}
                />
              )}
              <Container component="main">{children}</Container>
              <Footer data={{ siteTitle: data.site.siteMetadata.title }} />
            </>
          )}
        />
      </MaterialUiThemeProvider>
    </StyledComponentThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageHeaderBackgroundImage: PropTypes.string,
  pageTitle: PropTypes.string,
}

export default Layout
