import React from 'react'
import PropTypes from 'prop-types'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Box } from '@material-ui/core'

import materialUiTheme from '../../material-ui-theme'

const HeaderLogoImage = ({ onClick }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "ma-chicken-logo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 156) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Box onClick={onClick}>
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </S.Box>
  )
}

const S = {
  Box: styled(Box)`
    cursor: pointer;
    position: relative;
    top: ${materialUiTheme.spacing(2)}px;
    width: 112px;
  `,
}

HeaderLogoImage.propTypes = {
  onClick: PropTypes.func,
}

export default HeaderLogoImage
