import { createMuiTheme } from '@material-ui/core/styles'

export const fonts = {
  'Belmar-Bold': ['Belmar-Bold', '"Helvetica Neue"', 'sans-serif'].join(','),
  'Belmar-Normal': ['Belmar-Normal', '"Helvetica Neue"', 'sans-serif'].join(
    ','
  ),
  'BelmarCnd-Bold': ['BelmarCnd-Bold', '"Helvetica Neue"', 'sans-serif'].join(
    ','
  ),
  Brush455BT: ['Brush455BT', '"Helvetica Neue"', 'sans-serif'].join(','),
}

const theme = createMuiTheme({
  typography: {
    fontFamily: fonts['Belmar-Normal'],
    h1: {
      fontFamily: fonts['Belmar-Bold'],
    },
    h2: {
      fontFamily: fonts['Belmar-Bold'],
    },
    h3: {
      fontFamily: fonts['Belmar-Bold'],
    },
    h4: {
      fontFamily: fonts['Belmar-Bold'],
    },
    h5: {
      fontFamily: fonts['Belmar-Bold'],
    },
  },
  overrides: {
    MuiButton: {
      text: { fontFamily: fonts['Belmar-Bold'] },
    },
    MuiFab: {
      root: {
        fontFamily: fonts['Belmar-Bold'],
      },
    },
  },
  palette: {
    common: {
      transparent: 'rgba(255, 255, 255, 0)'
    },
    primary: {
      main: '#415117',
      light: '#708139',
    },
    secondary: {
      dark: '#631A0A',
      light: '#C99E8F',
      main: '#923C1F',
    },
  },
})

export default theme
