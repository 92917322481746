import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import {
  Box,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from '@material-ui/core'

import materialUiTheme from '../material-ui-theme'

const MobileNavigationMenu = ({
  open,
  routesData,
  onClose,
  onOpen,
  onNavigationMenuItemClick,
}) => {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box
        width={240}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <List>
          {routesData.map(({ id, label, path, isActive }) => (
            <ListItem button selected={isActive} key={id}>
              <S.ListItemText
                primary={label}
                onClick={() => onNavigationMenuItemClick(path)}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </SwipeableDrawer>
  )
}

const S = {
  ListItemText: styled(ListItemText)`
    color: ${materialUiTheme.palette.secondary.dark};
  `,
}

MobileNavigationMenu.propTypes = {
  routesData: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onNavigationItemClick: PropTypes.func,
}

export default MobileNavigationMenu
