import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { Typography, Grid, Container } from '@material-ui/core'
import { Parallax } from 'react-parallax'
import { Bounce } from 'react-reveal'

import materialUiTheme from '../material-ui-theme'
import media from '../utils/media'

const PageHeader = props => {
  const { backgroundImage, pageTitle } = props

  return (
    <S.Parallax bgImage={backgroundImage} strength={300}>
      <Container>
        <S.Grid container alignContent="flex-end" justify="center">
          <S.Grid2 item>
            <Bounce>
              <S.PageTitle>{pageTitle}</S.PageTitle>
            </Bounce>
          </S.Grid2>
        </S.Grid>
      </Container>
    </S.Parallax>
  )
}

const S = {
  Grid: styled(Grid)`
    height: 520px;

    ${media.sm`
      height: 480px;
    `}

    &:before {
      background-color: rgba(0, 0, 0, 0.5);
      content: '';
      width: 100%;
      z-index: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 0;
    }
  `,
  Grid2: styled(Grid)`
    margin-bottom: ${materialUiTheme.spacing(10)}px;
    z-index: 10;

    ${media.sm`
      ${materialUiTheme.spacing(5)}px;
    `}
  `,

  PageTitle: styled(Typography).attrs({
    variant: 'h3',
  })`
    color: ${materialUiTheme.palette.common.white};
    text-align: center;
  `,
  Parallax: styled(Parallax)`
    margin-top: 108px;
  `,
}

PageHeader.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
}

export default PageHeader
