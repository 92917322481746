import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import { Box } from '@material-ui/core'
import { Slide } from 'react-reveal'

import materialUiTheme from '../../material-ui-theme'

import media from '../../utils/media'

const FooterLogoImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "ma-chicken-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 156) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <S.Box>
      <Slide left>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </Slide>
    </S.Box>
  )
}

const S = {
  Box: styled(Box)`
    margin: auto;
    width: 88px;

    ${media.md`
      position: absolute;
      top: ${materialUiTheme.spacing(2)}px;
    `}
  `,
}

FooterLogoImage.propTypes = {}

export default FooterLogoImage
