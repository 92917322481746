import React from 'react'

import styled from 'styled-components'

import { Fab as MaterialUIFab } from '@material-ui/core'

const Fab = styled(({ isExtendedVariant, ...others }) => (
  <MaterialUIFab {...others} />
)).attrs(({ variant }) => ({
  isExtendedVariant: variant === 'extended',
}))`
  ${({ isExtendedVariant }) => `
    ${isExtendedVariant &&
      `
       min-width: 160px;
       padding: 0 32px;
    `}
`}
`

export default Fab
